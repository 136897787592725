import { defineStore } from "pinia";
export const useUserNotifications = defineStore("userNotifications", {
	state: () => ({
		number_all_notification: 0,
		number_unread_notification: 5,
	}),

	actions: {
		setNotification(val) {
			this.number_all_notification = val.all;
			this.number_unread_notification = val.unread;
		},
	},

	getters: {
		getNumberAll() {
			return this.number_all_notification;
		},
		getNumberUnread() {
			return this.number_unread_notification;
		},
	},
});
