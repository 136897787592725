import dayjs from "dayjs";
import "dayjs/locale/ru";
import timezone from "dayjs/plugin/timezone";

dayjs.locale("ru");
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Moscow");

export default defineNuxtPlugin(() => ({
	provide: {
		dayjs: dayjs,
	},
}));
