import { default as indexelijEHUBnUMeta } from "/opt/docker/pages/applications/diagnostics/[id]/index.vue?macro=true";
import { default as indexdS6DqtkzkQMeta } from "/opt/docker/pages/applications/diagnostics/add/index.vue?macro=true";
import { default as indexSaHRmM7WdeMeta } from "/opt/docker/pages/applications/dtp-repair/[id]/index.vue?macro=true";
import { default as indexkJFqGCtDH0Meta } from "/opt/docker/pages/applications/dtp-repair/add/index.vue?macro=true";
import { default as indexzQaW9Q3jyaMeta } from "/opt/docker/pages/applications/repair/[id]/index.vue?macro=true";
import { default as indexaQmZoUaCEMMeta } from "/opt/docker/pages/applications/repair/add/index.vue?macro=true";
import { default as indexjkqNWeuC1sMeta } from "/opt/docker/pages/applications/tire-service/[id]/index.vue?macro=true";
import { default as indext9zTf7xi2yMeta } from "/opt/docker/pages/applications/tire-service/add/index.vue?macro=true";
import { default as index7JDBQoFE1rMeta } from "/opt/docker/pages/applications/to/[id]/index.vue?macro=true";
import { default as indexU9MQoJYEsZMeta } from "/opt/docker/pages/applications/to/add/index.vue?macro=true";
import { default as indexSUO7MoP9MAMeta } from "/opt/docker/pages/applications/appeals/index.vue?macro=true";
import { default as indexRbkCUWRwSGMeta } from "/opt/docker/pages/applications/diagnostics/index.vue?macro=true";
import { default as indexxuIIKqTaVoMeta } from "/opt/docker/pages/applications/dtp-repair/index.vue?macro=true";
import { default as indexnFBBuhIK6HMeta } from "/opt/docker/pages/applications/repair/index.vue?macro=true";
import { default as indexG60uS0oGkgMeta } from "/opt/docker/pages/applications/tire-service/index.vue?macro=true";
import { default as indexhQZiKIUUyDMeta } from "/opt/docker/pages/applications/to/index.vue?macro=true";
import { default as applicationstJGdzPzDKWMeta } from "/opt/docker/pages/applications.vue?macro=true";
import { default as indexbogwRDBkHgMeta } from "/opt/docker/pages/auto-policy/index.vue?macro=true";
import { default as indexPwcVAz3FddMeta } from "/opt/docker/pages/car-accident/[id]/index.vue?macro=true";
import { default as indexUg3nqrnfR7Meta } from "/opt/docker/pages/car-accident/add/[id]/index.vue?macro=true";
import { default as indexItBuSuzoFpMeta } from "/opt/docker/pages/car-accident/add/index.vue?macro=true";
import { default as indexVNz0i0W0ldMeta } from "/opt/docker/pages/car-accident/index.vue?macro=true";
import { default as indexi6MBN1KgIcMeta } from "/opt/docker/pages/cars/index.vue?macro=true";
import { default as indexsWiwm9unDIMeta } from "/opt/docker/pages/index.vue?macro=true";
import { default as indexFuhblLDqLmMeta } from "/opt/docker/pages/instructions/creating/index.vue?macro=true";
import { default as indexRrjTglJhsaMeta } from "/opt/docker/pages/instructions/index.vue?macro=true";
import { default as instructionsmxrKfzyGsbMeta } from "/opt/docker/pages/instructions.vue?macro=true";
import { default as indexecHqu2clOSMeta } from "/opt/docker/pages/login/index.vue?macro=true";
import { default as indexlfUrcQusNKMeta } from "/opt/docker/pages/login/new-password/index.vue?macro=true";
import { default as indexW2IkVzKITKMeta } from "/opt/docker/pages/login/password-recovery/index.vue?macro=true";
import { default as index3UFUrAovQ9Meta } from "/opt/docker/pages/notifications/index.vue?macro=true";
import { default as index5fpBHL7ESNMeta } from "/opt/docker/pages/penalties/[id]/application-contesting/index.vue?macro=true";
import { default as indexmaI5BC7tX4Meta } from "/opt/docker/pages/penalties/[id]/index.vue?macro=true";
import { default as indexQNOCsSOBdqMeta } from "/opt/docker/pages/penalties/index.vue?macro=true";
import { default as penaltiesctfwaof4GjMeta } from "/opt/docker/pages/penalties.vue?macro=true";
import { default as indexPx2R7pqVlLMeta } from "/opt/docker/pages/profile/index.vue?macro=true";
import { default as index1Uj3FtW6DRMeta } from "/opt/docker/pages/replacement-cars/index.vue?macro=true";
import { default as index4Plwl0iVmVMeta } from "/opt/docker/pages/road-bills/[id]/application-contesting/index.vue?macro=true";
import { default as indexV4BxkSoBC9Meta } from "/opt/docker/pages/road-bills/[id]/index.vue?macro=true";
import { default as indexUb1Dazr1CEMeta } from "/opt/docker/pages/road-bills/index.vue?macro=true";
export default [
  {
    name: indexelijEHUBnUMeta?.name ?? "applications-diagnostics-id",
    path: indexelijEHUBnUMeta?.path ?? "/applications/diagnostics/:id()",
    meta: indexelijEHUBnUMeta || {},
    alias: indexelijEHUBnUMeta?.alias || [],
    redirect: indexelijEHUBnUMeta?.redirect,
    component: () => import("/opt/docker/pages/applications/diagnostics/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexdS6DqtkzkQMeta?.name ?? "applications-diagnostics-add",
    path: indexdS6DqtkzkQMeta?.path ?? "/applications/diagnostics/add",
    meta: indexdS6DqtkzkQMeta || {},
    alias: indexdS6DqtkzkQMeta?.alias || [],
    redirect: indexdS6DqtkzkQMeta?.redirect,
    component: () => import("/opt/docker/pages/applications/diagnostics/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexSaHRmM7WdeMeta?.name ?? "applications-dtp-repair-id",
    path: indexSaHRmM7WdeMeta?.path ?? "/applications/dtp-repair/:id()",
    meta: indexSaHRmM7WdeMeta || {},
    alias: indexSaHRmM7WdeMeta?.alias || [],
    redirect: indexSaHRmM7WdeMeta?.redirect,
    component: () => import("/opt/docker/pages/applications/dtp-repair/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexkJFqGCtDH0Meta?.name ?? "applications-dtp-repair-add",
    path: indexkJFqGCtDH0Meta?.path ?? "/applications/dtp-repair/add",
    meta: indexkJFqGCtDH0Meta || {},
    alias: indexkJFqGCtDH0Meta?.alias || [],
    redirect: indexkJFqGCtDH0Meta?.redirect,
    component: () => import("/opt/docker/pages/applications/dtp-repair/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexzQaW9Q3jyaMeta?.name ?? "applications-repair-id",
    path: indexzQaW9Q3jyaMeta?.path ?? "/applications/repair/:id()",
    meta: indexzQaW9Q3jyaMeta || {},
    alias: indexzQaW9Q3jyaMeta?.alias || [],
    redirect: indexzQaW9Q3jyaMeta?.redirect,
    component: () => import("/opt/docker/pages/applications/repair/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexaQmZoUaCEMMeta?.name ?? "applications-repair-add",
    path: indexaQmZoUaCEMMeta?.path ?? "/applications/repair/add",
    meta: indexaQmZoUaCEMMeta || {},
    alias: indexaQmZoUaCEMMeta?.alias || [],
    redirect: indexaQmZoUaCEMMeta?.redirect,
    component: () => import("/opt/docker/pages/applications/repair/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexjkqNWeuC1sMeta?.name ?? "applications-tire-service-id",
    path: indexjkqNWeuC1sMeta?.path ?? "/applications/tire-service/:id()",
    meta: indexjkqNWeuC1sMeta || {},
    alias: indexjkqNWeuC1sMeta?.alias || [],
    redirect: indexjkqNWeuC1sMeta?.redirect,
    component: () => import("/opt/docker/pages/applications/tire-service/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indext9zTf7xi2yMeta?.name ?? "applications-tire-service-add",
    path: indext9zTf7xi2yMeta?.path ?? "/applications/tire-service/add",
    meta: indext9zTf7xi2yMeta || {},
    alias: indext9zTf7xi2yMeta?.alias || [],
    redirect: indext9zTf7xi2yMeta?.redirect,
    component: () => import("/opt/docker/pages/applications/tire-service/add/index.vue").then(m => m.default || m)
  },
  {
    name: index7JDBQoFE1rMeta?.name ?? "applications-to-id",
    path: index7JDBQoFE1rMeta?.path ?? "/applications/to/:id()",
    meta: index7JDBQoFE1rMeta || {},
    alias: index7JDBQoFE1rMeta?.alias || [],
    redirect: index7JDBQoFE1rMeta?.redirect,
    component: () => import("/opt/docker/pages/applications/to/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexU9MQoJYEsZMeta?.name ?? "applications-to-add",
    path: indexU9MQoJYEsZMeta?.path ?? "/applications/to/add",
    meta: indexU9MQoJYEsZMeta || {},
    alias: indexU9MQoJYEsZMeta?.alias || [],
    redirect: indexU9MQoJYEsZMeta?.redirect,
    component: () => import("/opt/docker/pages/applications/to/add/index.vue").then(m => m.default || m)
  },
  {
    name: applicationstJGdzPzDKWMeta?.name ?? "applications",
    path: applicationstJGdzPzDKWMeta?.path ?? "/applications",
    meta: applicationstJGdzPzDKWMeta || {},
    alias: applicationstJGdzPzDKWMeta?.alias || [],
    redirect: applicationstJGdzPzDKWMeta?.redirect,
    component: () => import("/opt/docker/pages/applications.vue").then(m => m.default || m),
    children: [
  {
    name: indexSUO7MoP9MAMeta?.name ?? "applications-appeals",
    path: indexSUO7MoP9MAMeta?.path ?? "appeals",
    meta: indexSUO7MoP9MAMeta || {},
    alias: indexSUO7MoP9MAMeta?.alias || [],
    redirect: indexSUO7MoP9MAMeta?.redirect,
    component: () => import("/opt/docker/pages/applications/appeals/index.vue").then(m => m.default || m)
  },
  {
    name: indexRbkCUWRwSGMeta?.name ?? "applications-diagnostics",
    path: indexRbkCUWRwSGMeta?.path ?? "diagnostics",
    meta: indexRbkCUWRwSGMeta || {},
    alias: indexRbkCUWRwSGMeta?.alias || [],
    redirect: indexRbkCUWRwSGMeta?.redirect,
    component: () => import("/opt/docker/pages/applications/diagnostics/index.vue").then(m => m.default || m)
  },
  {
    name: indexxuIIKqTaVoMeta?.name ?? "applications-dtp-repair",
    path: indexxuIIKqTaVoMeta?.path ?? "dtp-repair",
    meta: indexxuIIKqTaVoMeta || {},
    alias: indexxuIIKqTaVoMeta?.alias || [],
    redirect: indexxuIIKqTaVoMeta?.redirect,
    component: () => import("/opt/docker/pages/applications/dtp-repair/index.vue").then(m => m.default || m)
  },
  {
    name: indexnFBBuhIK6HMeta?.name ?? "applications-repair",
    path: indexnFBBuhIK6HMeta?.path ?? "repair",
    meta: indexnFBBuhIK6HMeta || {},
    alias: indexnFBBuhIK6HMeta?.alias || [],
    redirect: indexnFBBuhIK6HMeta?.redirect,
    component: () => import("/opt/docker/pages/applications/repair/index.vue").then(m => m.default || m)
  },
  {
    name: indexG60uS0oGkgMeta?.name ?? "applications-tire-service",
    path: indexG60uS0oGkgMeta?.path ?? "tire-service",
    meta: indexG60uS0oGkgMeta || {},
    alias: indexG60uS0oGkgMeta?.alias || [],
    redirect: indexG60uS0oGkgMeta?.redirect,
    component: () => import("/opt/docker/pages/applications/tire-service/index.vue").then(m => m.default || m)
  },
  {
    name: indexhQZiKIUUyDMeta?.name ?? "applications-to",
    path: indexhQZiKIUUyDMeta?.path ?? "to",
    meta: indexhQZiKIUUyDMeta || {},
    alias: indexhQZiKIUUyDMeta?.alias || [],
    redirect: indexhQZiKIUUyDMeta?.redirect,
    component: () => import("/opt/docker/pages/applications/to/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexbogwRDBkHgMeta?.name ?? "auto-policy",
    path: indexbogwRDBkHgMeta?.path ?? "/auto-policy",
    meta: indexbogwRDBkHgMeta || {},
    alias: indexbogwRDBkHgMeta?.alias || [],
    redirect: indexbogwRDBkHgMeta?.redirect,
    component: () => import("/opt/docker/pages/auto-policy/index.vue").then(m => m.default || m)
  },
  {
    name: indexPwcVAz3FddMeta?.name ?? "car-accident-id",
    path: indexPwcVAz3FddMeta?.path ?? "/car-accident/:id()",
    meta: indexPwcVAz3FddMeta || {},
    alias: indexPwcVAz3FddMeta?.alias || [],
    redirect: indexPwcVAz3FddMeta?.redirect,
    component: () => import("/opt/docker/pages/car-accident/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexUg3nqrnfR7Meta?.name ?? "car-accident-add-id",
    path: indexUg3nqrnfR7Meta?.path ?? "/car-accident/add/:id()",
    meta: indexUg3nqrnfR7Meta || {},
    alias: indexUg3nqrnfR7Meta?.alias || [],
    redirect: indexUg3nqrnfR7Meta?.redirect,
    component: () => import("/opt/docker/pages/car-accident/add/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexItBuSuzoFpMeta?.name ?? "car-accident-add",
    path: indexItBuSuzoFpMeta?.path ?? "/car-accident/add",
    meta: indexItBuSuzoFpMeta || {},
    alias: indexItBuSuzoFpMeta?.alias || [],
    redirect: indexItBuSuzoFpMeta?.redirect,
    component: () => import("/opt/docker/pages/car-accident/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexVNz0i0W0ldMeta?.name ?? "car-accident",
    path: indexVNz0i0W0ldMeta?.path ?? "/car-accident",
    meta: indexVNz0i0W0ldMeta || {},
    alias: indexVNz0i0W0ldMeta?.alias || [],
    redirect: indexVNz0i0W0ldMeta?.redirect,
    component: () => import("/opt/docker/pages/car-accident/index.vue").then(m => m.default || m)
  },
  {
    name: indexi6MBN1KgIcMeta?.name ?? "cars",
    path: indexi6MBN1KgIcMeta?.path ?? "/cars",
    meta: indexi6MBN1KgIcMeta || {},
    alias: indexi6MBN1KgIcMeta?.alias || [],
    redirect: indexi6MBN1KgIcMeta?.redirect,
    component: () => import("/opt/docker/pages/cars/index.vue").then(m => m.default || m)
  },
  {
    name: indexsWiwm9unDIMeta?.name ?? "index",
    path: indexsWiwm9unDIMeta?.path ?? "/",
    meta: indexsWiwm9unDIMeta || {},
    alias: indexsWiwm9unDIMeta?.alias || [],
    redirect: indexsWiwm9unDIMeta?.redirect,
    component: () => import("/opt/docker/pages/index.vue").then(m => m.default || m)
  },
  {
    name: instructionsmxrKfzyGsbMeta?.name ?? undefined,
    path: instructionsmxrKfzyGsbMeta?.path ?? "/instructions",
    meta: instructionsmxrKfzyGsbMeta || {},
    alias: instructionsmxrKfzyGsbMeta?.alias || [],
    redirect: instructionsmxrKfzyGsbMeta?.redirect,
    component: () => import("/opt/docker/pages/instructions.vue").then(m => m.default || m),
    children: [
  {
    name: indexFuhblLDqLmMeta?.name ?? "instructions-creating",
    path: indexFuhblLDqLmMeta?.path ?? "creating",
    meta: indexFuhblLDqLmMeta || {},
    alias: indexFuhblLDqLmMeta?.alias || [],
    redirect: indexFuhblLDqLmMeta?.redirect,
    component: () => import("/opt/docker/pages/instructions/creating/index.vue").then(m => m.default || m)
  },
  {
    name: indexRrjTglJhsaMeta?.name ?? "instructions",
    path: indexRrjTglJhsaMeta?.path ?? "",
    meta: indexRrjTglJhsaMeta || {},
    alias: indexRrjTglJhsaMeta?.alias || [],
    redirect: indexRrjTglJhsaMeta?.redirect,
    component: () => import("/opt/docker/pages/instructions/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexecHqu2clOSMeta?.name ?? "login",
    path: indexecHqu2clOSMeta?.path ?? "/login",
    meta: indexecHqu2clOSMeta || {},
    alias: indexecHqu2clOSMeta?.alias || [],
    redirect: indexecHqu2clOSMeta?.redirect,
    component: () => import("/opt/docker/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexlfUrcQusNKMeta?.name ?? "login-new-password",
    path: indexlfUrcQusNKMeta?.path ?? "/login/new-password",
    meta: indexlfUrcQusNKMeta || {},
    alias: indexlfUrcQusNKMeta?.alias || [],
    redirect: indexlfUrcQusNKMeta?.redirect,
    component: () => import("/opt/docker/pages/login/new-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexW2IkVzKITKMeta?.name ?? "login-password-recovery",
    path: indexW2IkVzKITKMeta?.path ?? "/login/password-recovery",
    meta: indexW2IkVzKITKMeta || {},
    alias: indexW2IkVzKITKMeta?.alias || [],
    redirect: indexW2IkVzKITKMeta?.redirect,
    component: () => import("/opt/docker/pages/login/password-recovery/index.vue").then(m => m.default || m)
  },
  {
    name: index3UFUrAovQ9Meta?.name ?? "notifications",
    path: index3UFUrAovQ9Meta?.path ?? "/notifications",
    meta: index3UFUrAovQ9Meta || {},
    alias: index3UFUrAovQ9Meta?.alias || [],
    redirect: index3UFUrAovQ9Meta?.redirect,
    component: () => import("/opt/docker/pages/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: penaltiesctfwaof4GjMeta?.name ?? undefined,
    path: penaltiesctfwaof4GjMeta?.path ?? "/penalties",
    meta: penaltiesctfwaof4GjMeta || {},
    alias: penaltiesctfwaof4GjMeta?.alias || [],
    redirect: penaltiesctfwaof4GjMeta?.redirect,
    component: () => import("/opt/docker/pages/penalties.vue").then(m => m.default || m),
    children: [
  {
    name: index5fpBHL7ESNMeta?.name ?? "penalties-id-application-contesting",
    path: index5fpBHL7ESNMeta?.path ?? ":id()/application-contesting",
    meta: index5fpBHL7ESNMeta || {},
    alias: index5fpBHL7ESNMeta?.alias || [],
    redirect: index5fpBHL7ESNMeta?.redirect,
    component: () => import("/opt/docker/pages/penalties/[id]/application-contesting/index.vue").then(m => m.default || m)
  },
  {
    name: indexmaI5BC7tX4Meta?.name ?? "penalties-id",
    path: indexmaI5BC7tX4Meta?.path ?? ":id()",
    meta: indexmaI5BC7tX4Meta || {},
    alias: indexmaI5BC7tX4Meta?.alias || [],
    redirect: indexmaI5BC7tX4Meta?.redirect,
    component: () => import("/opt/docker/pages/penalties/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexQNOCsSOBdqMeta?.name ?? "penalties",
    path: indexQNOCsSOBdqMeta?.path ?? "",
    meta: indexQNOCsSOBdqMeta || {},
    alias: indexQNOCsSOBdqMeta?.alias || [],
    redirect: indexQNOCsSOBdqMeta?.redirect,
    component: () => import("/opt/docker/pages/penalties/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexPx2R7pqVlLMeta?.name ?? "profile",
    path: indexPx2R7pqVlLMeta?.path ?? "/profile",
    meta: indexPx2R7pqVlLMeta || {},
    alias: indexPx2R7pqVlLMeta?.alias || [],
    redirect: indexPx2R7pqVlLMeta?.redirect,
    component: () => import("/opt/docker/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: index1Uj3FtW6DRMeta?.name ?? "replacement-cars",
    path: index1Uj3FtW6DRMeta?.path ?? "/replacement-cars",
    meta: index1Uj3FtW6DRMeta || {},
    alias: index1Uj3FtW6DRMeta?.alias || [],
    redirect: index1Uj3FtW6DRMeta?.redirect,
    component: () => import("/opt/docker/pages/replacement-cars/index.vue").then(m => m.default || m)
  },
  {
    name: index4Plwl0iVmVMeta?.name ?? "road-bills-id-application-contesting",
    path: index4Plwl0iVmVMeta?.path ?? "/road-bills/:id()/application-contesting",
    meta: index4Plwl0iVmVMeta || {},
    alias: index4Plwl0iVmVMeta?.alias || [],
    redirect: index4Plwl0iVmVMeta?.redirect,
    component: () => import("/opt/docker/pages/road-bills/[id]/application-contesting/index.vue").then(m => m.default || m)
  },
  {
    name: indexV4BxkSoBC9Meta?.name ?? "road-bills-id",
    path: indexV4BxkSoBC9Meta?.path ?? "/road-bills/:id()",
    meta: indexV4BxkSoBC9Meta || {},
    alias: indexV4BxkSoBC9Meta?.alias || [],
    redirect: indexV4BxkSoBC9Meta?.redirect,
    component: () => import("/opt/docker/pages/road-bills/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexUb1Dazr1CEMeta?.name ?? "road-bills",
    path: indexUb1Dazr1CEMeta?.path ?? "/road-bills",
    meta: indexUb1Dazr1CEMeta || {},
    alias: indexUb1Dazr1CEMeta?.alias || [],
    redirect: indexUb1Dazr1CEMeta?.redirect,
    component: () => import("/opt/docker/pages/road-bills/index.vue").then(m => m.default || m)
  }
]