import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAccidentDetail, LazyAccidentDetailInterfacesIAccident, LazyAccidentDetailInterfacesIAccidentApplicationDetailAttributes, LazyAccidentDetailInterfacesIAccidentDetailAttributes, LazyAccidentDetailInterfacesIAccidentDetailService, LazyAccidentDetailInterfacesIAccidentDetailState, LazyAccidentDetailModelsAccident, LazyAccidentDetailModelsAccidentApplicationDetailService, LazyAccidentDetailModelsAccidentDetailService, LazyAccidentDetailTypesTAccidentApplicationDetailFetchData, LazyAccidentDetailTypesTAccidentDetailFetchData, LazyAccidentDetailTypesTProps, LazyApplicationContestingInfo, LazyApplicationContestingInfoInterfacesIComplainInfoProps, LazyApplicationContestingInfoModelsComplainInfo, LazyApplicationContestingInfoModelsComplainInfoApiService, LazyApplicationContestingInfoModelsComplainInfoService, LazyApplicationContestingInfoTypesTComplainGuid, LazyApplicationContesting, LazyApplicationFormCard, LazyApplicationFormCardTypeTFormData, LazyApplication, LazyApplicationModelsApplicationState, LazyApplicationTypesTApplicationState, LazyAsideMenu, LazyBubble, LazyCarAccidentCard, LazyCarListTable, LazyCarModelNumber, LazyChallenge, LazyFormGroup, LazyMessageResult, LazyPaymentFormIPaymentFormProps, LazyPaymentForm, LazyPayment, LazyResultBlock, LazyTimer, LazyUploadingFiles, LazyCalendar, LazyCheckbox, LazyDropdown, LazyInputMask, LazyInputSwitch, LazyInputText, LazyMultiSelect, LazyPassword, LazyTextarea, LazyButton, LazyColumn, LazyDataTable, LazyCard, LazyDivider, LazySplitter, LazySplitterPanel, LazyTabView, LazyTabPanel, LazyDialog, LazyOverlayPanel, LazyMenu, LazyMessage, LazyGalleria, LazyBadge, LazyChip, LazyTag } from '#components'
const lazyGlobalComponents = [
  ["AccidentDetail", LazyAccidentDetail],
["AccidentDetailInterfacesIAccident", LazyAccidentDetailInterfacesIAccident],
["AccidentDetailInterfacesIAccidentApplicationDetailAttributes", LazyAccidentDetailInterfacesIAccidentApplicationDetailAttributes],
["AccidentDetailInterfacesIAccidentDetailAttributes", LazyAccidentDetailInterfacesIAccidentDetailAttributes],
["AccidentDetailInterfacesIAccidentDetailService", LazyAccidentDetailInterfacesIAccidentDetailService],
["AccidentDetailInterfacesIAccidentDetailState", LazyAccidentDetailInterfacesIAccidentDetailState],
["AccidentDetailModelsAccident", LazyAccidentDetailModelsAccident],
["AccidentDetailModelsAccidentApplicationDetailService", LazyAccidentDetailModelsAccidentApplicationDetailService],
["AccidentDetailModelsAccidentDetailService", LazyAccidentDetailModelsAccidentDetailService],
["AccidentDetailTypesTAccidentApplicationDetailFetchData", LazyAccidentDetailTypesTAccidentApplicationDetailFetchData],
["AccidentDetailTypesTAccidentDetailFetchData", LazyAccidentDetailTypesTAccidentDetailFetchData],
["AccidentDetailTypesTProps", LazyAccidentDetailTypesTProps],
["ApplicationContestingInfo", LazyApplicationContestingInfo],
["ApplicationContestingInfoInterfacesIComplainInfoProps", LazyApplicationContestingInfoInterfacesIComplainInfoProps],
["ApplicationContestingInfoModelsComplainInfo", LazyApplicationContestingInfoModelsComplainInfo],
["ApplicationContestingInfoModelsComplainInfoApiService", LazyApplicationContestingInfoModelsComplainInfoApiService],
["ApplicationContestingInfoModelsComplainInfoService", LazyApplicationContestingInfoModelsComplainInfoService],
["ApplicationContestingInfoTypesTComplainGuid", LazyApplicationContestingInfoTypesTComplainGuid],
["ApplicationContesting", LazyApplicationContesting],
["ApplicationFormCard", LazyApplicationFormCard],
["ApplicationFormCardTypeTFormData", LazyApplicationFormCardTypeTFormData],
["Application", LazyApplication],
["ApplicationModelsApplicationState", LazyApplicationModelsApplicationState],
["ApplicationTypesTApplicationState", LazyApplicationTypesTApplicationState],
["AsideMenu", LazyAsideMenu],
["Bubble", LazyBubble],
["CarAccidentCard", LazyCarAccidentCard],
["CarListTable", LazyCarListTable],
["CarModelNumber", LazyCarModelNumber],
["Challenge", LazyChallenge],
["FormGroup", LazyFormGroup],
["MessageResult", LazyMessageResult],
["PaymentFormIPaymentFormProps", LazyPaymentFormIPaymentFormProps],
["PaymentForm", LazyPaymentForm],
["Payment", LazyPayment],
["ResultBlock", LazyResultBlock],
["Timer", LazyTimer],
["UploadingFiles", LazyUploadingFiles],
["Calendar", LazyCalendar],
["Checkbox", LazyCheckbox],
["Dropdown", LazyDropdown],
["InputMask", LazyInputMask],
["InputSwitch", LazyInputSwitch],
["InputText", LazyInputText],
["MultiSelect", LazyMultiSelect],
["Password", LazyPassword],
["Textarea", LazyTextarea],
["Button", LazyButton],
["Column", LazyColumn],
["DataTable", LazyDataTable],
["Card", LazyCard],
["Divider", LazyDivider],
["Splitter", LazySplitter],
["SplitterPanel", LazySplitterPanel],
["TabView", LazyTabView],
["TabPanel", LazyTabPanel],
["Dialog", LazyDialog],
["OverlayPanel", LazyOverlayPanel],
["Menu", LazyMenu],
["Message", LazyMessage],
["Galleria", LazyGalleria],
["Badge", LazyBadge],
["Chip", LazyChip],
["Tag", LazyTag],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
