const v1 = "/api/v1";

export const api = {
	login: `${v1}/auth/login/`,
	refreshToken: `${v1}/auth/token_refresh/`,
	resetPassword: `${v1}/auth/reset/`,
	userInfo: `${v1}/user/`,
	userNotification: `${v1}/user/notification/`,
	logout: `${v1}/auth/logout/`,
	notesList: `${v1}/note/`,
	policy: `${v1}/policy/`,
	auto: `${v1}/auto/`,
	autoShort: `${v1}/auto/short/`,
	autoReplacement: `${v1}/auto/replacement/`,
	penalty: `${v1}/penalty/`,
	penaltyId: (id) => `${v1}/penalty/${id}/`,
	roadBill: `${v1}/road_bill/`,
	roadBillId: (id) => `${v1}/road_bill/${id}/`,
	penaltyComplaint: `${v1}/penalty/complaint/`,
	roadBillComplaint: `${v1}/road_bill/complaint/`,
	penaltyComplaintInfo: (id) => `${v1}/penalty/complaint/${id}/`,
	roadBillComplaintInfo: (id) => `${v1}/road_bill/complaint/${id}/`,
	carAccidents: `${v1}/accident/`,
	getCarAccident: (id) => `${v1}/accident/${id}/`,
	carAccidentFirstStep: `${v1}/accident/step1/`,
	carAccidentSecondStep: `${v1}/accident/step2/`,
	applications: `${v1}/appeal/`,
	complaint: `${v1}/complaint/`,
	selectUsers: `${v1}/select/driver/`,
	selectAuto: `${v1}/auto/short/`,
	selectAccident: `${v1}/select/accident/`,
	selectNotification: `${v1}/select/notify/`,
};
