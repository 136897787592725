/* global useAuth */
import { useFetch, useRuntimeConfig } from "nuxt/app";

export const $useFetch = <DataT = any, ErrorT = any>(request: string, opts: object) => {
	const config = useRuntimeConfig();
	const { data: session }: { data: any } = useAuth();

	const baseURL: string = config.public.API_URL;

	return useFetch<DataT, ErrorT>(request, {
		baseURL,
		headers: {
			Authorization: `Bearer ${session.value?.user?.accessToken}`,
		},
		...opts,
	});
};
