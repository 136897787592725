export enum Routes {
	Penalties = "/penalties",
	Login = "/login",
	TollRoads = "/road-bills",
	Profile = "/profile",
	AccidentsList = "/car-accident",
	AddAccident = "/car-accident/add",
	InsurancePolicy = "/auto-policy",
	ApplicationsList = "/applications",
	ApplicationsListDtpRepair = "/applications/dtp-repair",
	ApplicationsListRepair = "/applications/repair",
	ApplicationsListDiagnostics = "/applications/diagnostics",
	ApplicationsListTireService = "/applications/tire-service",
	ApplicationsListTO = "/applications/to",
	ApplicationsListAppeals = "/applications/appeals",
	Instructions = "/instructions",
	StatusCar = "/cars",
	ReplacementCars = "/replacement-cars",
}
