import type { IAccident } from "~/components/global/accident-detail/interfaces/IAccident";

export class Accident<T> implements IAccident<T> {
	constructor(private state: Ref<T>) {}

	get getState(): T {
		return this.state.value;
	}

	updateState(data: T): void {
		this.state.value = { ...data };
	}
}
