import type { UseFetchOptions } from "#app";
import type { TFetchError } from "@/assets/ts/types/TFetchError";
import { api } from "~/api/api";
import type { TFetchDataNumberNotification } from "@/assets/ts/types/TFetchDataNumberNotification";

export function getNumberNotification(opt?: UseFetchOptions<TFetchDataNumberNotification>) {
	return $useFetch<TFetchDataNumberNotification, TFetchError>(api.userNotification, {
		default: () => ({}),
		method: "GET",
		transform: (mutations: { data: TFetchDataNumberNotification }): TFetchDataNumberNotification => mutations.data,
		...opt,
	});
}
