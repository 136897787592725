import revive_payload_client_4sVQNw7RlN from "/opt/docker/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/docker/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/docker/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/opt/docker/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/docker/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/opt/docker/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/docker/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/docker/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_W86eSGFqyZ from "/opt/docker/node_modules/nuxt-viewport/dist/runtime/plugin.client.mjs";
import primevue_plugin_egKpok8Auk from "/opt/docker/.nuxt/primevue-plugin.mjs";
import plugin_client_8fz26Kfk5j from "/opt/docker/node_modules/nuxt-primevue/dist/runtime/plugin.client.mjs";
import plugin_AOuQ1DYzjk from "/opt/docker/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/opt/docker/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import dayjs_pGiXRjcsJO from "/opt/docker/plugins/dayjs.ts";
import sentry_client_shVUlIjFLk from "/opt/docker/plugins/sentry.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_W86eSGFqyZ,
  primevue_plugin_egKpok8Auk,
  plugin_client_8fz26Kfk5j,
  plugin_AOuQ1DYzjk,
  chunk_reload_client_UciE0i6zes,
  dayjs_pGiXRjcsJO,
  sentry_client_shVUlIjFLk
]