import type { IAccidentDetailService } from "~/components/global/accident-detail/interfaces/IAccidentDetailService";
import type { IAccident } from "~/components/global/accident-detail/interfaces/IAccident";
import type { RouteParamValue } from "vue-router";
import { api } from "~/api/api";
import type { TAccidentDetailFetchData } from "~/components/global/accident-detail/types/TAccidentDetailFetchData";
import type { TFetchError } from "@/assets/ts/types/TFetchError";
import type { AsyncData } from "#app";
import type { IAccidentDetailState } from "~/components/global/accident-detail/interfaces/IAccidentDetailState";

export class AccidentDetailService implements IAccidentDetailService<TAccidentDetailFetchData> {
	constructor(
		private state: IAccident<IAccidentDetailState>,
		private accidentGuid: string | RouteParamValue[],
	) {}

	get getAccidentGuid(): string | RouteParamValue[] {
		return this.accidentGuid;
	}

	requestToAccidentDetail(): AsyncData<TAccidentDetailFetchData | null, TFetchError> {
		return $useFetch<TAccidentDetailFetchData, TFetchError>(api.getCarAccident(this.getAccidentGuid), {
			method: "GET",
		});
	}

	async generateDetailData(): Promise<void> {
		const { data } = await this.requestToAccidentDetail();

		if (!data.value) return;

		this.state.updateState({
			type: data.value.data.type,
			accident_info: {
				author: data.value.data.attributes.user.attributes.name,
				auto: data.value.data.attributes.auto.attributes.model,
				appeal: data.value.data.attributes.appeal,
			},
			projection: data.value.data.attributes.projection,
			files: data.value.data.attributes.documentLinks,
			odometer: `${data.value.data.attributes.odometer}`,
			status: data.value.data.attributes.status,
		});
	}
}
